@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.dateResponce {
    color: #757488 !important;
    font-size: 14px;
    font-weight: bold !important;
    background-color: #E6E4EB !important;
    padding-left: 60px;
}

.picker_response {
    position: relative;

    .icon_one,
    .icon_two {
        position: absolute;
        user-select: contain;
    }

    .icon_one {
        top: 8px;
        left: 10px;
    }

    .icon_two {
        right: 10px;
        top: 9px;
    }
}

.nunDataResponse {
    width: 35px;
    height: 35px;
    background: #8466FF26 0% 0% no-repeat padding-box;
    border-radius: 100%;
    text-align: center;
    letter-spacing: 0px;
    color: #8466FF;
    font-weight: bold;
    font-stretch: normal;
    font-size: 12px;
    line-height: 35px;
}

.name_friends {
    letter-spacing: 0px;
    color: #757488;
    font-size: 16px;
    line-height: 25px;
}

.text_friends {
    text-align: left;
    font: normal normal normal 20px/30px Montserrat;
    letter-spacing: 0px;
    color: #1B1464;
}